import '../styles/Navbar.css';
import '../styles/Line.css';
import Button from '../components/Button';
import Logo from '../images/coral.png';

function Navbar() {
  return (
    <>
      <nav>
        <div className='navbar-container'>
          <div className='logo-holder'>
            <img src={Logo} alt="Coral Software Logo" className='logo' />
          </div>
          {/* <Button color='pink' text='Contact'/> */}
        </div>
      </nav>
    </>
  );
}

export default Navbar;