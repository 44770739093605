import Navbar from '../components/Navbar';
import ProjectMobile from '../components/ProjectMobile.jsx';
import Project from '../components/Project';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import Button from '../components/Button';
import Line from '../components/Line';
import Service from '../components/Service';
import coral_hero from '../images/coral_hero.svg';
// css

function App() {

  const servicesContent = [
    {
      title: 'Web Design & Development',
      body: 'We take a clean and functional approach to our designs, with a goal to make navigating our sites intuitive for users. Our team can work with third party or client designs. We also offer full stack development for all web applications.'
    },
    {
      title: 'Web Hosting',
      body: 'We provide full web hosting for both existing web applications as well as any projects developed by us. Our team also provides maintenance and updates for existing web applications.'
    },
    {
      title: 'Content Management Systems (CMS)',
      body: 'We can develop a unique content management system (CMS) for your website to allow you to dynamically change the content of your site all on your own.'
    }
    
  ]

  const projectContent = [
    {
      id: 'sd_studio',
      title: 'SD Studio',
      body: (<div className='col fullwidth'>
        <p>SD Studio serves as a portfolio website designed for an interior design business located in Sydney. Its primary purpose is to showcase the various curtain packages offered by the business.</p>
        <ul className='project-list'>
          <li>Web Design</li>
          <li>Portfolio</li>
          <li>Mobile Web Development</li>
        </ul>
      </div>),
      href: 'https://sdstudio.live'
    },
    
    {
      id: 'technotronics',
      title: 'Technotronics',
      body: (<div>
        <p>Technotronics Business Systems was established in 1994. It's an online service and solutions centre in addition to a store that serves all types of customers and businesses.</p>
        <ul className='project-list'>
          <li>BigCommerce Development</li>
          <li>Ecommerce</li>
          <li>Web Development</li>
        </ul>
      </div>),
      href: 'https://technotronics.com.au/'
    },

    {
      id: 'hdt',
      title: 'HDT Owners Club of NSW',
      body: (<div>
        <p>The HDT Owners Club of NSW, established in 2003, provides a community for owners of genuine HDT Special Vehicles, offering a variety of activities including car shows, drives, and social events.</p>
        <ul className='project-list'>
          <li>Web Development</li>
          <li>Backend Development</li>
        </ul>
      </div>),
      href: 'https://hdtownersclub.com.au'
    }
  ]

  const scrollToContact = (e) => {
    let contact = document.getElementById("contact");
    e.preventDefault();
    contact && contact.scrollIntoView({behavior: "smooth"});
  }

  const isHover = 'ontouchstart' in window || navigator.msMaxTouchPoints;

  // projectContent.map((props) => (
  //   <Project props={props}/>
  // ))

  return (
    <div className='App'>
      <Navbar/>

      <div className="bg-blue pbl">
        <div className="container">
          <div className="row">
            <div className="halfwidth">
              <h1 className='ptxl pbxs'><span className='gradient-text'>Coral</span> Software Development</h1>
              <p className='pbxs mw500'>
                We are a team of qualified full-stack developers who have
                a passion for all areas of software development, primarily
                focusing on web applications.
              </p>
              <div className="row gap30 wrap">
              <Button color='blue' text='Contact' function={scrollToContact}/>
            </div>
            </div>
            <div className="halfwidth row justify-center not-mobile">
              <img src={coral_hero} alt="img" className='coral-graphic'/>
            </div>
          </div>
        </div>
      </div>
      
      <div className="bg-light ptl pbl">
        <div className="container">
          <Line/>
          <h2 className='pbxs'>Our Services</h2>
          <div className="service-wrapper">
            <Service {...servicesContent[0]}/>
            <Service {...servicesContent[1]}/>
            <Service {...servicesContent[2]}/>
          </div>
        </div>
      </div>

      <div className="bg-blue ptl pbl">
        <div className="container">
          <Line/>
          <h2 className='pbxs'>Our Projects</h2>

          <h3 className='pbxs'>Featured Project - SD Studio</h3>

          <iframe src="https://sdstudio.live" scrolling="no" className='sdstudio-iframe'> </iframe>

          <div className='project-wrapper ptxs'>
            {
              isHover ? 
              projectContent.map((props) => (
                <ProjectMobile props={props}/>
              )) :
              projectContent.map((props) => (
                <Project props={props}/>
              ))
            }
          </div>
        </div>
      </div>
      
      <div id='contact' className="bg-light ptm pbm">
        <div className="container">
          <Line/>
          <h2 className="pbxs">Contact us</h2>
          <Contact/>
        </div>
      </div>

      <Footer/>
    </div>
  );
}

export default App;