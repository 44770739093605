import Button from './Button';
import '../styles/Project.css';

function Projects(props) {

  const styles = {
    backgroundImage: `url(https://reef.coralsoftware.dev/photos/${props.props.id}.jpg)`
  };

  return (
    <div className="project mobile">
      <div className='project-thumbnail' style={styles}></div>
      <div className='preAnim'></div>
      <div className='project-title'>
       <span>{props.props.title ? (props.props.title) : 'Placeholder'}</span>
       {props.props.body}
       <Button color='blue' text='See Project' href={props.props.href}/>
      </div>
    </div>
  );
}

export default Projects;