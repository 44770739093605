import { useState } from 'react';
import '../styles/Contact.css'
import Airform from 'react-airform'



function Contact() {
    
    const [animation, setAnimation] = useState('pre')

    const MouseOver = () => {
        setAnimation('post')
    }

    const MouseOut = () => {
        setAnimation('pre')
    }

    return (
        <div className="ptxl pbxl">
            <div className="contact">
                <h3 className='center-text pbs'>Contact</h3>
                <Airform email="hello@coralsoftware.au">
                    <input type="text" name="firstName" placeholder="First Name"/>
                    <input type="text" name="lastName" placeholder="Last Name"/>
                    <input type="email" name="email" placeholder="Email"/>
                    <textarea name="message" placeholder='Details'/>
                    <div className="submit-wrapper">
                        <input 
                        type="submit" 
                        className='submit' 
                        onMouseOver={MouseOver} 
                        onMouseOut={MouseOut}/>
                        <span className={animation}></span>
                    </div>
                </Airform>
            </div>
        </div>
    );
}

export default Contact;