import { animate, motion } from 'framer-motion';
import useMediaQuery from '../hooks/useMediaQuery';
import Button from './Button';
import '../styles/Project.css';

function Projects(props) {

  const isHover = useMediaQuery("hover: hover");

  const styles = {
    backgroundImage: `url(https://reef.coralsoftware.dev/photos/${props.props.id}.jpg)`
  };

  let titleAnim = {
    hover: {
      transform: 'translateY(0%)',
      transition: {
        duration: 0.8,
        type: "tween",
        ease: "easeIn"
      }
    }
  };

  return (
    <motion.div 
    whileHover='hover'
    className="project desktop">
      <div className='project-thumbnail' style={styles}></div>
      <motion.div className='preAnim'></motion.div>
      <motion.div className='project-title' variants={titleAnim}>
       <span>{props.props.title ? (props.props.title) : 'Placeholder'}</span>
       {props.props.body}
       <Button color='blue' text='See Project' href={props.props.href}/>
      </motion.div>
    </motion.div>
  );
}

export default Projects;