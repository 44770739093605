import '../styles/Footer.css';
import Logo from '../images/coral.png';

function Footer() {
  return (
    <>
      <footer>
        <div className="container row space-between">
          <div className='row align-center gap30 m-col m-align-start'>
            <p>Email us: <br />  hello@coralsoftware.au</p>
            <p>ABN: <br /> 37 705 063 271</p>
          </div>
          
            <div className='logo-holder bg-black'>
              <img src={Logo} alt="Coral Software Logo" className='logo' />
            </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;